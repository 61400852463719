
import { computed } from '@vue/runtime-core'
import store from '../../../../store'
export default {
  setup() {
    return {
      results: computed(() => store.getters.resentResults),
      ranking: computed(() => store.getters.rankingData)
    }
  }
}
